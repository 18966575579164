.container {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorComponentBox {
    padding: 10px 20px;
    width: auto;
    max-width: 700px;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0 3px 7px -3px;
}

.routesBox {
    padding: 5px 20px;
    background-color: #e5ebed;
    width: 400px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0 3px 7px -3px;
}

.route {
    background-color: #d62e4f;
    color: #fff;
    padding: 1px 10px;
    border-radius: 10px;
}
